/**
* Generated automatically at built-time (2024-11-12T12:33:04.509Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-viinikaappi",templateKey: "sites/83-1ae27623-6ed3-433a-85df-5fec322e3b5d"};